import { MapperConfiguration, MappingPair } from "@dynamic-mapper/mapper";
import {
  FormLibraryRow,
  IFormLibraryDto,
  RAFRuleAction,
} from "../../../RAFComponents/models/Common/FormLibraryRow";
import { ConvertObjkeysToLowerCase } from "../../helpers/RMutils";
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";

export const ConvertFormLibraryRowToFormLibraryDto = (
  formLibraryRow: FormLibraryRow
): IFormLibraryDto => {
  const FormLibraryRowToIFormLibraryDto = new MappingPair<
    FormLibraryRow,
    IFormLibraryDto
  >();
  const FormLibraryRowToFormLibraryDtoConfiguration = new MapperConfiguration(
    (cfg) => {
      cfg
        .createAutoMap(FormLibraryRowToIFormLibraryDto, {})
        .forSourceMember("FormLibrarySettings", (opt) => opt.ignore())
        .forSourceMember("FormData", (opt) => opt.ignore())
        .forSourceMember("CollectionName", (opt) => opt.ignore())
        .forSourceMember("ResponsiveMode", (opt) => opt.ignore());
    }
  );

  const mapper = FormLibraryRowToFormLibraryDtoConfiguration.createMapper();
  return mapper.map(FormLibraryRowToIFormLibraryDto, formLibraryRow);
};

export const ConvertFormLibraryDtoToFormLibrary = (
  FormLibraryDto: IFormLibraryDto
): FormLibraryRow => {
  const IFormLibraryDtoToFormLibraryRow = new MappingPair<
    IFormLibraryDto,
    FormLibraryRow
  >();
  const IFormLibraryDtoToFormLibraryRowConfiguration = new MapperConfiguration(
    (cfg) => {
      cfg.createAutoMap(IFormLibraryDtoToFormLibraryRow, {
        FormLibrarySettings: () => null,
        FormData: () => null,
        CollectionName: () => null,
        FormRules: (opt) =>
          opt.mapFrom((src) =>
            isNotNullAndUndefined(src) &&
            isNotNullAndUndefined(src.FormRules) &&
            src.FormRules.length > 0
              ? ConvertFormRulesArrayIntoRuleModelArray(src.FormRules)
              : null
          ),
        ResponsiveMode: () => null,
      });
    }
  );

  const mapper = IFormLibraryDtoToFormLibraryRowConfiguration.createMapper();
  return mapper.map(IFormLibraryDtoToFormLibraryRow, FormLibraryDto);
};

function ConvertFormRulesArrayIntoRuleModelArray(
  formRules?: RAFRuleAction[]
): RAFRuleAction[] {
  let formRulesList: RAFRuleAction[] = [];
  if (formRules != null) {
    formRules.forEach((item) => {
      ConvertObjkeysToLowerCase(item.RRuleModel);
      formRulesList.push(item);
    });
  }
  return formRulesList;
}
