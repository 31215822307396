import { ListRequest } from "../../RAFComponents/models/Common/ListRequest";
import { RAFCustomFilter } from "../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
//import { BusinessAction } from "../RMModules/BusinessRules/BusinessAction";

export class RMConstants {
    //static baseAPIUrl = 'http://192.168.1.10:8000/Skytrust2020.Api/';
    //static baseAPIUrl = 'http://localhost:56340/';
    //static rafMasterAPIUrl = 'http://localhost:56340/';
    static rafMasterAPIUrl = (process.env.REACT_APP_BASE_MASTER_API_URL !== null && process.env.REACT_APP_BASE_MASTER_API_URL !== undefined) ? process.env.REACT_APP_BASE_MASTER_API_URL : 'http://localhost:5001/';
    //static baseAPIUrl = 'http://localhost/RAFMaster.Base.API/';
    //static skytrustUrl = 'http://skytrust.co/';
    static cookieName = 'ACSESSIONC';
    static HorizontalRAFFormElement = 'HorizontalRAFFormElement';
    static VerticalRAFFormElement = 'VerticalRAFFormElement';
    static DropdownFetchCount = 50;
}

export enum RAFEntityType {
    Standard = 'Standard',
    DataList = 'DataList',
    DynamicList = 'DynamicList',
    SystemForm = 'SystemForm',
    UserForm = 'UserForm',
}

export class RMStorageKey {
    static recentClickedMenu = '_recentClickedMenu';
    static currentMenuCategory = 'currentMenuCategory';
    static isAdmin = 'isAdmin';
    //static isUserSettings = 'isUserSettings';
    static entities = 'entities';
    static all_Entities = '_all_Entities';
    static allEntities_entityType = 'allEntities_';
    static allRelatedEntities_modulename = '_allRelatedEntities_';
    static allRelatedAttributes_modulename = '_allRelatedAttributes_';
    static all_Forms = '_all_Forms';
    static bptemplatestepselection = '_bptemplatestepselection_';
    static bpstepselection = '_bpstepselection_';
    static recentBPTemplate = '_recentBPTemplate';
    static recentBPRelatedTo = '_recentBPRelatedTo';
    static currentEntity = 'currentEntity';
    static recentFormLibrary = '_recentFormLibrary';
    static FormLibraryEntity = '_form_library_Entity_';
    static FormLibraryCategoryType = '_form_library_CategoryType_';

    static recentInspectionLibrary = '_recentInspectionLibrary';
    static InspectionLibraryEntity = '_inspection_library_Entity_';
    static InspectionLibraryCategoryType = '_inspection_library_CategoryType_';
    static all_Inspections = '_all_Inspections';
}

export enum RAFBusinessCategory {
    Workflow = "Workflow",
    Automation = "Automation",
    BusinessProcess = "Business Process",
    Pipeline = "Pipeline"

}

export enum RAFTriggerMode {
    Created = "Created",
    Modified = "Modified",
    CreatedORModified = "Created/Modified",
    Deleted = "Deleted",
    ViewFilters = "ViewFilters",
    ConditionMatches = "ConditionMatches",
    ChangesFrom = "ChangesFrom",
    Scheduled = "Scheduled",
}

export enum RAFConditionType {
    ValueMatch = "ValueMatch",
    StateChange = "StateChange",
}

export enum RAFPageType {
    Default = "Default",
    Web = "Web",
    Create = "Create",
    Edit = "Edit",
    Details = "Details",
    Lookup = "Lookup",
    RelatedTo = "RelatedTo",
    DetailsSummary = "Details Summary",
    Custom = "Custom",
    Summary = "Summary",
    Card = "Card",
}

export enum RAFSectionType {
    Summary = "Summary",
    Details = "Details",
    RelatedSection = "RelatedSection",
}

export enum RAFSectionTypeValue {
    Summary = "Short Details",
    Details = "Details",
    RelatedSection = "Center List",
}

export enum RAFColumnCountValue {
    Summary = 4,
    Details = 1,
    RelatedSection = 1,
}

export enum RAFEntityDetailsType {
    Attributes = "Attributes",
    PageSettings = "Page Settings",
    CustomFields = "Custom Fields",
    PageLayout = "Page Layout",
    PageRule = "Page Rules",
    Automation = "Automation",
    Template = "Templates",
    ViewManagement = "Views",
    Reports = "Reports",
    FormFields = 'Form Fields',
    EntityFields = 'Customize Form',
    Pages = "Page Layouts",
    ProcessLibrary = "Business Process",
    Trigger = "Triggers",
    PageRelatedSection = "Related Sections",
}

export enum RAFBPStepStatus {
    NotCompleted = "Not Completed",
    Completed = "Completed",
    ToDo = "To-Do",
    Approved = "Approved",
    Rejected = "Rejected",
    RequestforInfo = "Request for Info",
    Queued = "Queued",
    Hidden = "Hidden",
    Skipped = "Skipped",
    Initiate = "Initiate",
    RequestApproval = "Request Approval",
    AwaitingforApproval = "Awaiting for Approval",
    Assigned = "Assigned",
    RequestReview = "Request Review",
    AwaitingforReview = "Awaiting for Review",
    Reviewed = "Reviewed",
    Cleared = "Cleared",
}

export enum RAFBPStepStatusDisplayText {
    Completed = "Complete",
    Approved = "Approve",
    Rejected = "Reject",
    Skipped = "Skip",
    Reviewed = "Review",
    RequestforInfo = "Request for Info",
}

export enum RAFBPStatus {
    Started = "Started",
    Scheduled = "Scheduled",
    InProgress = "In Progress",
    Completed = "Completed",
    Draft = "Draft"
}

export enum RAFBPCurrentStatus {
    Draft = "Draft",
    Published = "Published",
    Obsolete = "Obsolete",
}

export enum RAFBPViewName {
    Published = "published_process_templates",
    Draft = "draft_process_templates",
    Obsolete = "obsolete_process_templates",
    All = "all_process_templates"
}

export enum RAFProcessViewName {
    Completed = "completed_process",
    Running = "running_business_process",
    Scheduled = "scheduled_business_process",
    All = "all_processes"
}

export enum RAFDocumentViewName {
    Published = "all_content_library",
    Draft = "draft_content_library",
}

export enum RAFBPTabName {
    ProcessSteps = "Process Steps",
    RunningProcess = "Running Process",
    CompletedProcess = "Completed Process",
    VersionHistory = "Version History",
    Transactions = "Transactions",
    Questions = "Questions",
    InspectionItems = "Inspection Items",
    Designer = "Designer",
    Inprogress = "Inprogress",
    Scheduled = "Scheduled",
    History = "History",
    Versions = "Versions",
    Details = "Details",
    Records = "Records",
}

export enum RAFDeadlineUnit {
    BusinessDays = "Business days",
    CalendarDays = "Calendar days",
    Weeks = "Weeks",
    Months = "Months",
    Years = "Years"
}

export enum RAFDeadlineCalculation {
    Notset = "Not set",
    AfterProcessStart = "After process start",
    AfterPreviousTaskComplete = "After previous task complete",
    BeforeProcessDeadline = "Before process deadline",
    BeforeStartDate = "Before Start date",
    AfterStartDate = "After Start date",
    AfterAnotherStepComplete = "After another step complete",
    PromptUserWhenRun = "Prompt user",
    OnFileUpload = "On file upload"
}

export enum RAFExecutionOrder {
    //Sequential = "Sequential",
    //Parallel = "Parallel"
    AnyOrder = "Any Order",
    StepByStep = "Step By Step",
    Conditional = "Conditional",
}

export enum RAFWorkspaceRelatedtoType {
    View = "View",
    Module = "Module",
    Form = "Form",
    Category = "Category",
    Weblink = "Web link",
}

export enum RAFTemplateType {
    Email = "Email",
    SMS = "SMS",
    Signature = "Signature",
}

export enum RAFBPStepType {
    Item = "Item",
    Group = "Group",
    Stage = "Stage",
    Task = "To-Do",
    //Task = "Task",
    Checklist = "Checklist",
    //ToDo = "To-Do",
    ToDo = "CheckItem",
    Heading = "Heading",
    Decision = "Decision",
    Approval = "Approval",
    Question = "Question",
    Note = "Page",
    Review = "Review",
    Section = "Page",
    Signature = "Signature",
    FormComponent = "Form Component"
}

export enum RAFBPStepTypeIcon {
    Group = "fas fa-list-tree",
    Task = "far fa-calendar-circle-user",
    ToDo = "far fa-clipboard-check",
    Approval = "far fa-person-circle-check",
    Question = "far fa-ballot-check",
    Note = "far fa-memo",
    Review = "fas fa-star-sharp-half-stroke",
    Heading = "fas fa-heading",
    Signature = "fas fa-signature",
    FormComponent = "fas fa-signature"
}
export enum RAFBPStepTemplateStatus {
    Active = "Active",
    InActive = "InActive",
    Hidden = "Hidden"
}

export enum RAFProcessAssigneeType {
    ProcessStarter = "Process Starter",
    User = "User",
    Team = "Team",
    Approver = "Approver",
    Reviewer = "Reviewer",
    Partner = "Partner",
    Guest = "Guest",
    None = "None",
};

export class BpStepTemplateCloneRequest {
    SourceUID?: string;
    DestinationUID?: string;
    DestinationStepTemplateUID?: string;
}

export class BusinessRuleListRequest extends ListRequest {
    Category?: string;
}

export class BPStepDecisionModel {
    Decision: BPStepDecision[];
}
export class BPStepDecision {
    Conditions?: RAFCustomFilter;
    //Actions?: BusinessAction;
}

export enum RAFBPAction {
    Continue = "continue",
    End = "end",
}

export enum RMReportType {
    Process = 'Process',
}

export enum RMReportTypeDisplayName {
    Process = 'Processes',
}

export enum RAFRelatedContext {
    ContextObject = 'contextObject',
    RelatedObject = 'relatedObject',
}

export enum RAFDisplayType {
    Card = 'Card',
    Grid = 'Grid',
    Metric = 'Metric'
}

export enum RAFBPThemeTemplate {
    Induction = 'Induction',
    Inspection = 'Inspection',
    Evaluation = 'Evaluation',
    Default = 'Default',
    Incident = 'Incident',
    Assessment = 'Assessment'
}

export const RAFBPTemplateSettings = [
    { Id: RAFBPThemeTemplate.Induction, TabTitle: RAFBPTabName.Questions, StepType: RAFBPStepType.Question, ShowComplete: true, ShowWizardView: true, DisableSelection: true, ToggleMode: false, CompressImage: true, EnableScore: true },
    { Id: RAFBPThemeTemplate.Inspection, TabTitle: RAFBPTabName.InspectionItems, StepType: RAFBPStepType.Question, ShowComplete: true, ShowWizardView: false, DisableSelection: true, ToggleMode: false, CompressImage: true, EnableScore: true },
    { Id: RAFBPThemeTemplate.Evaluation, TabTitle: RAFBPTabName.Questions, StepType: RAFBPStepType.Question, ShowComplete: true, ShowWizardView: false, DisableSelection: true, ToggleMode: false, CompressImage: true, EnableScore: true },
    { Id: RAFBPThemeTemplate.Incident, TabTitle: RAFBPTabName.Questions, StepType: RAFBPStepType.Question, ShowComplete: true, ShowWizardView: false, DisableSelection: true, ToggleMode: false, CompressImage: false, EnableScore: true },
    { Id: RAFBPThemeTemplate.Assessment, TabTitle: RAFBPTabName.Questions, StepType: RAFBPStepType.Question, ShowComplete: true, ShowWizardView: false, DisableSelection: true, ToggleMode: false, CompressImage: false, EnableScore: false },
    { Id: RAFBPThemeTemplate.Default, TabTitle: RAFBPTabName.ProcessSteps, ShowComplete: true, ShowWizardView: false, DisableSelection: false, ToggleMode: true, CompressImage: true, EnableScore: true },
];

export enum RAFFormLibraryHeaders {
    UserDatalist = 'User DataList',
    Forms = 'Forms',
    Templates = 'Templates',
    Modules = 'System DataList',
}

export enum RAFFormFieldName {
    BusinessProcesss = 'business_process',
    BusinessProcesssUID = 'business_process_uid',
    BusinessProcesssStep = 'business_process_step',
    BusinessProcesssStepUID = 'business_process_step_uid',
}

export enum RAFGridView {
    TableView = 'TableView',
    ListView = 'ListView',
    CalendarView = 'Calendar',
    WeekView = 'WeekView',
    MonthView = 'MonthView',
    TimeSheetView = 'Timesheet',
}
export enum RAFGridViewDisplayname {
    TableView = 'Table',
    ListView = 'List',
    ActivityView = 'Activity view',
    CalendarView = 'Calendar',
    TimeSheetView = 'Timesheet',
}
export enum RAFGridViewIconCss {
    TableView = 'far fa-table',
    ListView = 'fad fa-list-ul',
    CalendarView = 'fas fa-calendar-days',
}

export const rafNoViewName = 'No view found';