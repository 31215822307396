import { MappingPair, MapperConfiguration } from "@dynamic-mapper/mapper";
import { PageLayoutJM, IPageLayoutJMDto } from "./PageLayoutJM";

export const ConvertPageLayoutJMToPageLayoutJMDto = (
  pageLayoutJM: PageLayoutJM
): IPageLayoutJMDto => {
  const pageLayoutJMToIPageLayoutJMDto = new MappingPair<
    PageLayoutJM,
    IPageLayoutJMDto
  >();
  const pageLayoutJMToPageLayoutJMDtoConfiguration = new MapperConfiguration(
    (cfg) => {
      cfg
        .createAutoMap(pageLayoutJMToIPageLayoutJMDto, {})
        .forSourceMember("AttributesList", (opt) => opt.ignore());
    }
  );

  const mapper = pageLayoutJMToPageLayoutJMDtoConfiguration.createMapper();
  return mapper.map(pageLayoutJMToIPageLayoutJMDto, pageLayoutJM);
};

export const ConvertPageLayoutJMDtoToPageLayoutJM = (
  pageLayoutJMDto: IPageLayoutJMDto
): PageLayoutJM => {
  const IPageLayoutJMDtoToPageLayoutJM = new MappingPair<
    IPageLayoutJMDto,
    PageLayoutJM
  >();
  const IPageLayoutJMDtoToPageLayoutJMConfiguration = new MapperConfiguration(
    (cfg) => {
      cfg.createAutoMap(IPageLayoutJMDtoToPageLayoutJM, {
        AttributesList: (opt) => opt.mapFrom((src) => null),
      });
    }
  );

  const mapper = IPageLayoutJMDtoToPageLayoutJMConfiguration.createMapper();
  return mapper.map(IPageLayoutJMDtoToPageLayoutJM, pageLayoutJMDto);
};
