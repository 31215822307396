import * as R from "ramda";
import Resizer from 'react-image-file-resizer';
import { GridColumnTemplates, GridColumnWidth } from "../../RAFComponents/Grid/RAFSFGridUtils";
import { RAFCustomFilter, RAFCustomOperator } from "../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showWarningToast } from "../../RAFComponents/Utility/RAFToastComponent";
import { getAllForms, getSessionStorage, removeSessionStorage, setSessionStorage } from "../../RAFComponents/helpers/AppHelper";
import { ContentType, RAFEntityName, RAFFilePreSignedURL, StorageKey } from "../../RAFComponents/helpers/Constants";
import { Guid, IsNullOrWhiteSpace, base64ToFile, fileUpload, getCompressedImage, getResizedImage, getSaveRequest, isConnectedToInternet, isNotEmptyArray, isNotNullAndUndefined, propertyOf } from "../../RAFComponents/helpers/utils";
import { AttributeRow } from "../../RAFComponents/models/Common/AttributeRow";
import { FormLibraryRow, IFormLibraryDto, RAFFormType } from "../../RAFComponents/models/Common/FormLibraryRow";
import { DocumentPreSignedUrlRequest, ListServiceRequest, ModuleListRequest, PageRelatedListRequest } from "../../RAFComponents/models/Common/ListRequest";
import { LookUpRow } from "../../RAFComponents/models/CompositeTypes/LookUpRow";
import * as repositoryActions from "../../RAFComponents/store/actions/repositoryActions";
import { EntityRow } from "../RMModules/Entity/EntityRow";
import { ConvertFormLibraryDtoToFormLibrary } from "../RMModules/FormLibrary/FormLibraryMappingRegister";
import { PageLayoutJM } from "../RMModules/RAFPage/PageLayoutJM";
import { ConvertPageLayoutJMToPageLayoutJMDto } from "../RMModules/RAFPage/PageLayoutMappingRegister";
import { RAFPageRelatedListRow } from "../RMModules/RAFPageRelatedList/RAFPageRelatedListRow";
import { RAFEntityType, RMStorageKey } from "./RMConstants";

export function flattenElements(elements) {
    let result = [];

    for (let element of elements) {
        if (element.elements) {
            result = result.concat(flattenElements(element.elements));
        } else {
            result.push(element);
        }
    }

    return result;
}

export function updateDisplayOrder(attributeList: AttributeRow[]) {
    let objAttributeList: AttributeRow[] = R.clone(attributeList);
    objAttributeList &&
        objAttributeList.forEach((item, index) => {
            if (isNotNullAndUndefined(item.AttributeSettings))
                item.DisplayOrder = index + 1;
        });
    return objAttributeList;
};

export function ConvertObjkeysToLowerCase(obj) {
    if (isNotNullAndUndefined(obj)) {


        if (obj instanceof Array) {
            for (var i in obj) {
                obj[i] = ConvertObjkeysToLowerCase(obj[i]);
            }
        }
        if (typeof (obj) === "string" || typeof (obj) === "number" || typeof (obj) === "boolean") {
            return obj;
        }
        const keys = Object.keys(obj);
        let n = keys.length;
        let lowKey;
        while (n--) {
            const key = keys[n];
            if (key === (lowKey = key.toLowerCase()))
                continue;
            obj[lowKey] = ConvertObjkeysToLowerCase(obj[key]);
            delete obj[key];
        }
    }
    return (obj);
}

export const getEntityById = (id?: string) => {
    let url = `Entity/Retrieve`;
    return new Promise<EntityRow>((resolve) => {
        if (!IsNullOrWhiteSpace(id)) {
            return repositoryActions.postDataAndGetResponse(
                url,
                { "EntityId": id },
                null,
                ContentType.applicationJson, false
            ).then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    resolve(response.data.Entity);
                } else {
                    resolve(null);
                }
            });
        } else {
            resolve(null);
        }
    });
};

export const getEntityByName = (entityName?: string) => {
    let url = `Entity/Retrieve`;
    return new Promise<EntityRow>((resolve) => {
        if (isNotNullAndUndefined(entityName)) {
            return repositoryActions.postDataAndGetResponse(
                url,
                { "ObjectName": entityName },
                null,
                ContentType.applicationJson, false
            ).then((response) => {
                if (isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.Entity)) {
                    resolve(response.data.Entity);
                } else {
                    resolve(null);
                }
            });
        } else {
            resolve(null);
        }
    });
};

export const CheckEntityExist = (entityName?: string) => {
    let url = `Entity/IsExist`;
    return new Promise<boolean>((resolve) => {
        return repositoryActions.postDataAndGetResponse(
            url,
            { "EntityName": entityName },
            null,
            ContentType.applicationJson, false
        ).then((response) => {
            if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                if (response.data)
                    resolve(true);
                else
                    resolve(false);
            }
            else {
                resolve(false);
            }
        });
    });
};

export const getEntityByObjectData = (objectData, returnNull?: boolean) => {
    return new Promise<EntityRow>((resolve) => {
        if (returnNull !== true) {
            let url = `Entity/Retrieve`;
            return repositoryActions.postDataAndGetResponse(
                url,
                objectData,
                null,
                ContentType.applicationJson, false
            ).then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    resolve(response.data.Entity);
                } else {
                    resolve(null);
                }
            });
        } else {
            resolve(null);
        }
    });
};

export function CheckFormExist(formName?: string, formCategory?: string) {
    return new Promise<boolean>((resolve /*, reject*/) => {
        getAllForms().then((allForms) => {
            let selectedFormLibraryRow: FormLibraryRow =
                allForms && allForms.find((x) => x.Title === formName && x.FormType === formCategory);
            if (isNotNullAndUndefined(selectedFormLibraryRow)) {
                resolve(true);
            } else {
                resolve(false);
            }
        });
    });
};

export function CheckFormIsExist(formName?: string, formCategory?: string) {
    return new Promise<boolean>((resolve /*, reject*/) => {
        getAllForms().then((allForms) => {
            let selectedFormLibraryRow: FormLibraryRow =
                allForms && allForms.find((x) => x.Title === formName && x.FormType === formCategory);
            if (isNotNullAndUndefined(selectedFormLibraryRow)) {
                resolve(true);
            } else {
                resolve(false);
            }
        });
    });
};


export function SaveEntity(entityRow?: EntityRow) {
    let id = isNotNullAndUndefined(entityRow) && isNotNullAndUndefined(entityRow.UID) ? entityRow.UID : null;
    let url = `Entity/Save`;
    return new Promise<string>((resolve /*, reject*/) => {
        return repositoryActions
            .postDataAndGetResponse(
                url,
                getSaveRequest(entityRow, id),
                null,
                ContentType.applicationJson,
                false
            )
            .then((response) => {
                if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.EntityId)
                ) {
                    removeSessionStorage(StorageKey.simpleEntity_modulename + entityRow.EntityName, true);
                    resolve(response.data.EntityId);
                } else {
                    showWarningToast("Sorry something went wrong !");
                    return (null);
                }
            });

    });
};

export const SavePageLayout = (pageLayoutJM: PageLayoutJM) => {
    let id = isNotNullAndUndefined(pageLayoutJM) && isNotNullAndUndefined(pageLayoutJM.UID) ? pageLayoutJM.UID : null;
    let pageLayout = ConvertPageLayoutJMToPageLayoutJMDto(pageLayoutJM);
    return new Promise<PageLayoutJM>((resolve) => {
        repositoryActions
            .postDataAndGetResponse(
                `PageLayout/Save`,
                getSaveRequest(pageLayout, id),
                null,
                ContentType.applicationJson
            )
            .then((response) => {
                if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.EntityId)
                ) {
                    removeSessionStorage(StorageKey.pageLayoutList_pageTypeentity + pageLayout.PageType + pageLayout.EntityUID, true);
                    let pageLayoutResponse: PageLayoutJM = pageLayoutJM;
                    pageLayoutResponse.UID = response.data.EntityId;
                    resolve(pageLayoutResponse);

                }
            });

    });
};

export function GetListByPage(pageUID?: string, entityUID?: string) {
    let pageRelatedListRequest = new PageRelatedListRequest();
    pageRelatedListRequest.EntityUID = isNotNullAndUndefined(entityUID) ? entityUID : null;
    pageRelatedListRequest.PageUID = isNotNullAndUndefined(pageUID) ? pageUID : null;
    let url = "PageRelatedList/ListByPage";

    return new Promise<RAFPageRelatedListRow[]>((resolve, reject) => {
        repositoryActions
            .postDataAndGetResponse(
                url,
                pageRelatedListRequest,
                null,
                ContentType.applicationJson
            )
            .then((response) => {

                if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.Entities)
                ) {
                    let relatedPages: RAFPageRelatedListRow[] = response.data.Entities;
                    relatedPages.push({
                        DisplayOrder: 0,
                        Name: "Details",
                        UID: Guid.newGuid(),
                        IsCollapsed: false,
                        IsVisible: true,
                        RelatedEntity: "Details",
                    });
                    relatedPages &&
                        relatedPages.sort((x, y) =>
                            x.DisplayOrder > y.DisplayOrder ? 1 : -1
                        );
                    resolve(relatedPages);
                }
                else {
                    resolve(null);
                }
            });

    });
}

export async function getEntityByObjectName(objectData): Promise<EntityRow> {
    const isOnline = await isConnectedToInternet();
    //if (isOnline === true) {
    return getEntityByObjectNameAPI(objectData);
    // }
    // else {
    //     return getEntityByObjectNameFB(objectData);
    // }
}

export const getEntityByObjectNameAPI = (objectData) => {
    return new Promise<EntityRow>((resolve) => {
        if (isNotNullAndUndefined(objectData) &&
            (isNotNullAndUndefined(objectData.ObjectName) || isNotNullAndUndefined(objectData.EntityId))) {
            const simpleEntityStorage = isNotNullAndUndefined(objectData.ObjectName) ?
                getSessionStorage(StorageKey.entity_modulename + objectData.ObjectName, true) : null;
            let entityRow: EntityRow = isNotNullAndUndefined(simpleEntityStorage) &&
                !IsNullOrWhiteSpace(simpleEntityStorage) &&
                isNotNullAndUndefined(JSON.parse(simpleEntityStorage)) ? JSON.parse(simpleEntityStorage) : null;
            if (isNotNullAndUndefined(entityRow) && isNotNullAndUndefined(entityRow.UID)) {
                resolve(entityRow);
            } else {
                let url = `Entity/Retrieve`;
                return repositoryActions.postDataAndGetResponse(
                    url,
                    objectData,
                    null,
                    ContentType.applicationJson, false
                ).then((response) => {
                    if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entity)) {
                        setSessionStorage(StorageKey.entity_modulename + objectData.ObjectName, true, JSON.stringify(response.data.Entity));
                        resolve(response.data.Entity);
                    } else {
                        resolve(null);
                    }
                });
            }
        } else {
            resolve(null);
        }
    });
};

export function getEntityDisplayNameByModuleName(entityName?: string) {
    let retVal = entityName;
    switch (entityName) {
        case RAFEntityName.FormLibrary:
            retVal = "Form Library";
            break;
        case RAFEntityName.InspectionLibrary:
            retVal = "Inspection Library";
            break;
        case "Inspection":
            retVal = "Inspection";
            break;
        case "Permit":
            retVal = "Permit";
            break;
        case "Take5":
            retVal = "Take 5";
            break;
        default:
            break;
    }
    return retVal;
}

export const formLibraryTemplates: GridColumnTemplates[] = [
    { key: "Title", value: "formLibraryTemplate" },
];

export const FormLibraryColumnWidths: GridColumnWidth[] = [
    { field: "Title", width: "400px" },
];

export const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 150, 150, 'JPEG', 100, 0,
        uri => {
            resolve(uri);
        },
        'base64'
    );
});

export function uploadFileAndGetFormData(formModel?: any, moduleName?: string, mode?: 'create' | 'edit', careShiftLogRow?: any) {
    let formData = formModel.data;
    const attachmentQuestions = formModel
        .getAllQuestions()
        .filter((question) => question.getType() === "file");
    const attachmentQuestionNames =
        attachmentQuestions &&
        attachmentQuestions.map((question) => question.name);
    let attachmentsToUpload = [];
    for (let i = 0; i < attachmentQuestionNames.length; i++) {
        const questionName = attachmentQuestionNames[i];
        attachmentsToUpload = formData[questionName];
    }




    return new Promise<any>((resolve, reject) => {
        if (isNotEmptyArray(attachmentsToUpload)) {

            Promise.all(
                attachmentsToUpload.map(async (file) => {
                    let fileContent = base64ToFile(file.content, file.name);
                    let filestoUpload = await fileUpload(fileContent, file.UID, moduleName);
                    if (isNotNullAndUndefined(filestoUpload)) {
                        let attachment: LookUpRow = {
                            UID: filestoUpload.entityId,
                            Value: filestoUpload.objectName,
                        };
                        return attachment;
                    } else {
                        let attachment: LookUpRow = {
                            UID: null,
                            Value: null,
                        };
                        return attachment;
                    }
                })
            ).then((responseAttachments) => {
                const attachments = isNotEmptyArray(responseAttachments) ? responseAttachments.filter(x => isNotNullAndUndefined(x.UID)) : [];
                for (let i = 0; i < attachmentQuestionNames.length; i++) {
                    const questionName = attachmentQuestionNames[i];
                    let existingAttachments = formData[questionName];
                    let attachmentsArray = attachments as LookUpRow[];
                    const updatedAttachments =
                        existingAttachments ?
                            existingAttachments.filter(
                                (existingAttachment) =>
                                    !attachmentsArray.some(
                                        (attachment) =>
                                            attachment.Value === existingAttachment.name
                                    )
                            ) : null;
                    if (isNotEmptyArray(updatedAttachments)) {
                        attachmentsArray = [...attachmentsArray, ...updatedAttachments];
                    }
                    // formModel.setValue(questionName, attachmentsArray);
                    formData[questionName] = attachmentsArray;
                }

                if (mode === 'edit') {
                    formModel.getAllQuestions().forEach(function (question) {
                        if (formData[question.name] === undefined) {
                            formData[question.name] = null; // some empty value you can write result[question.name] = undefined; but it will look weird
                        }
                    });
                }
                resolve(formData);

            });
        }
        else {
            formModel.getAllQuestions().forEach(function (question) {
                if (formData[question.name] === undefined) {
                    formData[question.name] = null; // some empty value you can write result[question.name] = undefined; but it will look weird
                }
            });

            resolve(formData);
        }

    });
}

export function getPredefinedURLAndUplodToS3(formModel?: any, moduleName?: string, mode?: 'create' | 'edit', existingFormData?: any) {
    let formModelData = R.clone(formModel.data);
    return new Promise<any>(async (resolve, reject) => {
        let s3UploadResponse = await uploadFileAndGetPredefinedURL(formModel, moduleName, existingFormData);
        let saveListRequest = [];
        const attachmentQuestions = formModel
            .getAllQuestions()
            .filter((question) => question.getType() === "file");
        const attachmentQuestionNames =
            attachmentQuestions &&
            attachmentQuestions.map((question) => question.name);

            
        const customQuestion = formModel
        .getAllQuestions()
        .filter((question) => (question.getType() === "complaincequestion" || question.getType() === "starratingquestion"));

         if(isNotEmptyArray(customQuestion)){
            customQuestion.map((question) => {
                const questionJsonObj = question["jsonObj"]
                const showEvidence = questionJsonObj["showEvidence"]
                if (showEvidence) {
                    attachmentQuestionNames.push(question.name);
                }
            })
         }

        if (isNotEmptyArray(s3UploadResponse)) {
            let attachments = [];

            s3UploadResponse.forEach((item) => {
                let attachment = { UID: item.UID, Value: item.DisplayName,QuestionName:item.QuestionName };
                attachments.push(attachment);
            });

            for (let i = 0; i < attachmentQuestionNames.length; i++) {
                const questionName = attachmentQuestionNames[i];
                //let existingAttachments = formModelData[questionName];
                let existingAttachments = findAttachments(formModelData,questionName);
                let questionAttachments = isNotEmptyArray(attachments) ? attachments.filter(x=>x.QuestionName === questionName) : [];
                let attachmentsArray = isNotEmptyArray(questionAttachments) ? questionAttachments : [];
                const updatedAttachments =
                    isNotEmptyArray(existingAttachments) ?
                        existingAttachments.filter(
                            (existingAttachment) =>
                                !attachmentsArray.some(
                                    (attachment) =>
                                        attachment.Value === existingAttachment.name
                                )
                        ) : null;
                if (isNotEmptyArray(updatedAttachments)) {
                    attachmentsArray = [...attachmentsArray, ...updatedAttachments];
                }

                let updatedAttachmentsArray = [];

                updatedAttachmentsArray = attachmentsArray.map((attachment) => {
                    let { ["QuestionName"]: _, ...rest } = attachment;
                    return rest;
                  });

                  let evidenceQuestion = customQuestion.find(x=>x.name === questionName);
                  if(isNotNullAndUndefined(evidenceQuestion)){
                    updateAttachments(formModelData,questionName+'_evidence',updatedAttachmentsArray);
                }
                  else{
                    updateAttachments(formModelData,questionName,updatedAttachmentsArray);
                }
                // formModel.setValue(questionName, attachmentsArray);
                //formModelData[questionName] = attachmentsArray;
            }

            s3UploadResponse.forEach((item) => {
                item["RelatedToType"] = moduleName;
                item["Entity"] = moduleName;
                delete item["QuestionName"];
                let entity = { Entity: item };
                saveListRequest.push(entity);
            });

            repositoryActions
                .postDataAndGetResponse(
                    "ContentLibrary/SaveList",
                    saveListRequest,
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (mode === 'edit') {
                        formModel.getAllQuestions().forEach(function (question) {
                            if (formModelData[question.name] === undefined) {
                                formModelData[question.name] = null; // some empty value you can write result[question.name] = undefined; but it will look weird
                            }
                        });
                    }
                    resolve(formModelData);

                });
        }
        else {
            formModel.getAllQuestions().forEach(function (question) {
                if (formModelData[question.name] === undefined) {
                    formModelData[question.name] = null; // some empty value you can write result[question.name] = undefined; but it will look weird
                }
            });

            resolve(formModelData);
        }
    });
}

function findAttachments(obj, questionName) {
    let evidenceValues = [];
    for (let key in obj) {
      // Check if the property is an item
      if (key === questionName || key.startsWith(questionName)) {
        // Get the evidence for the item
        let evidence = [];
        if (obj[key] !== null && obj[key][key + "_evidence"]) {
            evidence = obj[key][key + "_evidence"];
          } else {
            evidence = obj[key];
          }
  
        if (Array.isArray(evidence)) {
            evidence.map((e) => {
              e["QuestionName"] = questionName;
              evidenceValues.push(e);
            });
          }
      }
    }
    return evidenceValues;
  }
 
  function updateAttachments(obj: any, keyToFind: string, newValue: any) {
    for (let key in obj) {
      if (key === keyToFind) {
        // If the key matches the key to find, update its value
        obj[key] = newValue;
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        // If the value is an object, recursively update its properties
        updateAttachments(obj[key], keyToFind, newValue);
      }
    }
  }


export function uploadFileAndGetPredefinedURL(formModel, moduleName, existingFormData) {
    let formData = formModel.data;
    const attachmentQuestions = formModel
        .getAllQuestions()
        .filter((question) => question.getType() === "file");
    const attachmentQuestionNames =
        attachmentQuestions &&
        attachmentQuestions.map((question) => question.name);

        const customQuestion = formModel
        .getAllQuestions()
        .filter((question) => (question.getType() === "complaincequestion" || question.getType() === "starratingquestion"));

         if(isNotEmptyArray(customQuestion)){
            customQuestion.map((question) => {
                const questionJsonObj = question["jsonObj"]
                const showEvidence = questionJsonObj["showEvidence"]
                if (showEvidence) {
                    attachmentQuestionNames.push(question.name);
                }
            })
         }


       let attachmentsToUpload = [];
        
        for (let i = 0; i < attachmentQuestionNames.length; i++) {
            const questionName = attachmentQuestionNames[i];
            //let newAttachments = formData[questionName];
            let newAttachments = findAttachments(formData,questionName);
            if (isNotEmptyArray(newAttachments)) {
                if (isNotNullAndUndefined(existingFormData)) {
                    //let existingAttachments = existingFormData[questionName];
                    let existingAttachments = findAttachments(existingFormData,questionName);
                    if (isNotEmptyArray(existingAttachments)) {
                        newAttachments = newAttachments.filter(
                            (newAttachment) => !existingAttachments.some(existing => existing.UID === newAttachment.UID)
                        );
                    }
                }
    
                attachmentsToUpload = [...attachmentsToUpload, ...newAttachments];
                 // Add the questionName property to each object in attachmentsToUpload
    attachmentsToUpload = attachmentsToUpload.map(attachment => ({
        ...attachment,
        questionName: questionName
      }));
    
            }
        }



    let retVal = [];
    return new Promise<any>((resolve, reject) => {
        if (isNotEmptyArray(attachmentsToUpload)) {

            let url = "ContentLibrary/PreSignedUrl";
            let documentPreSignedUrlRequest = new DocumentPreSignedUrlRequest();
            documentPreSignedUrlRequest.Thumnail = true;
            documentPreSignedUrlRequest.Documents = [];
            Array.from(attachmentsToUpload).map(file => {
                documentPreSignedUrlRequest.Documents.push({
                    UID: file.UID,
                    FileName: file.name,
                    MimeType: file.type,
                    Entity: moduleName
                });
            });

            repositoryActions
                .postDataAndGetResponse(
                    url,
                    documentPreSignedUrlRequest,
                    null,
                    ContentType.applicationJson
                )
                .then(async (response) => {

                    if (
                        isNotNullAndUndefined(response) &&
                        isNotNullAndUndefined(response.data)
                    ) {
                        let fileUrls: RAFFilePreSignedURL[] = response.data;

                        if (isNotEmptyArray(fileUrls)) {


                            for (let i = 0; i < fileUrls.length; i++) {
                                const file = attachmentsToUpload && attachmentsToUpload.find(x => x.UID === fileUrls[i].EntityId);
                                let fileToUpload;
                                if (fileUrls[i].MimeType.startsWith('image/')) {
                                    let fileContent = base64ToFile(file.content, file.name);
                                    if (fileUrls[i].DocumentType === 'Thumbnail') {
                                        //resize image if mimetype is File and upload
                                        let resizedImage = await getResizedImage(fileContent);
                                        fileToUpload = resizedImage;
                                    } else {
                                        let compressedImage = await getCompressedImage(fileContent);
                                        fileToUpload = compressedImage;
                                    }
                                } else {
                                    if (fileUrls[i].DocumentType === 'File') {

                                    // Convert the base64 string to a Blob
                                    const response = await fetch(file.content);
                                    const blob = await response.blob();

                                    // Create a File from the Blob
                                    const newFile = new File([blob], fileUrls[i].ObjectName, { type: fileUrls[i].MimeType, lastModified: Date.now() });
                                    fileToUpload = newFile;
                                    }
                                }

                                const uploadUrl = fileUrls[i].UploadURL;
                                let filesToSave;
                               
                                if (isNotNullAndUndefined(uploadUrl) && isNotNullAndUndefined(fileToUpload)) {
                                    try {
                                        await uploadFileWithRetry(fileToUpload, uploadUrl);
                                        console.log(`File ${file.name} uploaded successfully`);
                                        if (fileUrls[i].DocumentType === 'File') {
                                            filesToSave = {
                                                UID: fileUrls[i].EntityId,
                                                DisplayName: fileUrls[i].ObjectName,
                                                FileName: fileUrls[i].ObjectName,
                                                MimeType: fileUrls[i].MimeType,
                                                FileSize: fileToUpload.size,
                                                DocumentType: fileUrls[i].DocumentType,
                                                CurrentStatus: ContentLibraryCurrentStatus.Published,
                                                QuestionName: file.questionName,
                                            };
                                            retVal.push({ ...filesToSave });
                                        }
                                    } catch (error) {
                                        console.error(`Failed to upload ${file.name}:`, error);
                                    }
                                }

                            }

                            resolve(retVal);
                        }
                        else {
                            resolve(null);
                        }
                    }
                    else {
                        resolve(null);
                    }
                });
        }
        else {
            resolve(null);
        }



    });
}

//backup method
export function getPredefinedURLAndUplodToS3Copy(formModel?: any, moduleName?: string, mode?: 'create' | 'edit', existingFormData?: any) {
    let formModelData = formModel.data;
    return new Promise<any>(async (resolve, reject) => {
        let s3UploadResponse = await uploadFileAndGetPredefinedURLCopy(formModel, moduleName, existingFormData);
        let saveListRequest = [];
        const attachmentQuestions = formModel
            .getAllQuestions()
            .filter((question) => question.getType() === "file");
        const attachmentQuestionNames =
            attachmentQuestions &&
            attachmentQuestions.map((question) => question.name);

        if (isNotEmptyArray(s3UploadResponse)) {
            let attachments: LookUpRow[] = [];

            s3UploadResponse.forEach((item) => {
                let attachment = { UID: item.UID, Value: item.DisplayName };
                attachments.push(attachment);
            });

            for (let i = 0; i < attachmentQuestionNames.length; i++) {
                const questionName = attachmentQuestionNames[i];
                let existingAttachments = formModelData[questionName];
                let attachmentsArray = isNotEmptyArray(attachments) ? attachments : [];
                const updatedAttachments =
                    isNotEmptyArray(existingAttachments) ?
                        existingAttachments.filter(
                            (existingAttachment) =>
                                !attachmentsArray.some(
                                    (attachment) =>
                                        attachment.Value === existingAttachment.name
                                )
                        ) : null;
                if (isNotEmptyArray(updatedAttachments)) {
                    attachmentsArray = [...attachmentsArray, ...updatedAttachments];
                }
                // formModel.setValue(questionName, attachmentsArray);
                formModelData[questionName] = attachmentsArray;
            }
            s3UploadResponse.forEach((item) => {
                item["RelatedToType"] = moduleName;
                item["Entity"] = moduleName;
                let entity = { Entity: item };
                saveListRequest.push(entity);
            });

            repositoryActions
                .postDataAndGetResponse(
                    "ContentLibrary/SaveList",
                    saveListRequest,
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (mode === 'edit') {
                        formModel.getAllQuestions().forEach(function (question) {
                            if (formModelData[question.name] === undefined) {
                                formModelData[question.name] = null; // some empty value you can write result[question.name] = undefined; but it will look weird
                            }
                        });
                    }
                    resolve(formModelData);

                });
        }
        else {
            formModel.getAllQuestions().forEach(function (question) {
                if (formModelData[question.name] === undefined) {
                    formModelData[question.name] = null; // some empty value you can write result[question.name] = undefined; but it will look weird
                }
            });

            resolve(formModelData);
        }
    });
}

export function uploadFileAndGetPredefinedURLCopy(formModel, moduleName, existingFormData) {
    let formData = formModel.data;
    const attachmentQuestions = formModel
        .getAllQuestions()
        .filter((question) => question.getType() === "file");
    const attachmentQuestionNames =
        attachmentQuestions &&
        attachmentQuestions.map((question) => question.name);
    let attachmentsToUpload = [];
    for (let i = 0; i < attachmentQuestionNames.length; i++) {
        const questionName = attachmentQuestionNames[i];
        let newAttachments = formData[questionName];
        if (isNotEmptyArray(newAttachments)) {
            if (isNotNullAndUndefined(existingFormData)) {
                let existingAttachments = existingFormData[questionName];
                if (isNotEmptyArray(existingAttachments)) {
                    newAttachments = newAttachments.filter(
                        (newAttachment) => !existingAttachments.some(existing => existing.UID === newAttachment.UID)
                    );
                }
            }

            attachmentsToUpload = [...attachmentsToUpload, ...newAttachments];
        }
    }

    let retVal = [];
    return new Promise<any>((resolve, reject) => {
        if (isNotEmptyArray(attachmentsToUpload)) {

            let url = "ContentLibrary/PreSignedUrl";
            let documentPreSignedUrlRequest = new DocumentPreSignedUrlRequest();
            documentPreSignedUrlRequest.Thumnail = true;
            documentPreSignedUrlRequest.Documents = [];
            Array.from(attachmentsToUpload).map(file => {
                documentPreSignedUrlRequest.Documents.push({
                    UID: file.UID,
                    FileName: file.name,
                    MimeType: file.type,
                    Entity: moduleName
                });
            });

            repositoryActions
                .postDataAndGetResponse(
                    url,
                    documentPreSignedUrlRequest,
                    null,
                    ContentType.applicationJson
                )
                .then(async (response) => {

                    if (
                        isNotNullAndUndefined(response) &&
                        isNotNullAndUndefined(response.data)
                    ) {
                        let fileUrls: RAFFilePreSignedURL[] = response.data;

                        if (isNotEmptyArray(fileUrls)) {


                            for (let i = 0; i < fileUrls.length; i++) {
                                const file = attachmentsToUpload && attachmentsToUpload.find(x => x.UID === fileUrls[i].EntityId);
                                let fileToUpload;
                                if (fileUrls[i].MimeType.startsWith('image/')) {
                                    let fileContent = base64ToFile(file.content, file.name);
                                    if (fileUrls[i].DocumentType === 'Thumbnail') {
                                        //resize image if mimetype is File and upload
                                        let resizedImage = await getResizedImage(fileContent);
                                        fileToUpload = resizedImage;
                                    } else {
                                        fileToUpload = fileContent;
                                    }
                                } else {
                                    if (fileUrls[i].DocumentType === 'File') {

                                        // Convert the base64 string to a Blob
                                        const response = await fetch(file.content);
                                        const blob = await response.blob();

                                        // Create a File from the Blob
                                        const newFile = new File([blob], fileUrls[i].ObjectName, { type: fileUrls[i].MimeType, lastModified: Date.now() });
                                        fileToUpload = newFile;
                                    }
                                }

                                const uploadUrl = fileUrls[i].UploadURL;
                                let filesToSave;

                                if (isNotNullAndUndefined(uploadUrl) && isNotNullAndUndefined(fileToUpload)) {
                                    try {
                                        await uploadFileWithRetry(fileToUpload, uploadUrl);
                                        console.log(`File ${file.name} uploaded successfully`);
                                        if (fileUrls[i].DocumentType === 'File') {
                                            filesToSave = {
                                                UID: fileUrls[i].EntityId,
                                                DisplayName: fileUrls[i].ObjectName,
                                                FileName: fileUrls[i].ObjectName,
                                                MimeType: fileUrls[i].MimeType,
                                                FileSize: fileToUpload.size,
                                                DocumentType: fileUrls[i].DocumentType,
                                                CurrentStatus: ContentLibraryCurrentStatus.Published
                                            };
                                            retVal.push({ ...filesToSave });
                                        }
                                    } catch (error) {
                                        console.error(`Failed to upload ${file.name}:`, error);
                                    }
                                }

                            }

                            resolve(retVal);
                        }
                        else {
                            resolve(null);
                        }
                    }
                    else {
                        resolve(null);
                    }
                });
        }
        else {
            resolve(null);
        }



    });
}

export function downloadAndGetPredifinedURL(recordIds?: string[]) {
    let url = "ContentLibrary/PreSignedDownloadUrl";
    let documentPreSignedUrlRequest = new DocumentPreSignedUrlRequest();
    documentPreSignedUrlRequest.Thumnail = true;
    documentPreSignedUrlRequest.Documents = [];
    Array.from(recordIds).map(recordId => {
        documentPreSignedUrlRequest.Documents.push({
            UID: recordId,
        });
    });

    return new Promise<RAFFilePreSignedURL[]>((resolve, reject) => {
        repositoryActions
            .postDataAndGetResponse(
                url,
                documentPreSignedUrlRequest,
                null,
                ContentType.applicationJson
            )
            .then(async (response) => {
                if (
                    isNotNullAndUndefined(response) &&
                    isNotEmptyArray(response.data)
                ) {
                    let downloadUrls: RAFFilePreSignedURL[] = response.data;
                    resolve(downloadUrls);
                }
                else {
                    resolve(null);
                }
            });
    });
}

const MAX_RETRIES = 3;
const RETRY_INTERVAL = 2000; // 2 seconds

async function uploadFileWithRetry(file, url, retries = MAX_RETRIES) {
    try {
        const response = await fetch(url, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': file.type,
            },

        });

        //console.log('response',response)
        if (!response.ok) {
            throw new Error('Upload failed');
        }

        return response;
    } catch (error) {
        console.log('error', error);
        if (retries > 0) {
            console.warn(`Retrying upload for ${file.name}, attempts left: ${retries}`);
            await new Promise(resolve => setTimeout(resolve, RETRY_INTERVAL));
            return uploadFileWithRetry(file, url, retries - 1);
        } else {
            throw new Error(`Failed to upload ${file.name} after ${MAX_RETRIES} attempts`);
        }
    }
}

export enum ContentLibraryCurrentStatus {
    Draft = "Draft",
    Published = "Published",
}

export function RetrieveFormLibraryById(id: string) {
    return new Promise<FormLibraryRow>((resolve) => {
        if (isNotNullAndUndefined(id)) {
            return repositoryActions.postDataAndGetResponse(
                `FormLibrary/Retrieve`,
                { "EntityId": id },
                null,
                ContentType.applicationJson, false
            ).then((response) => {
                if (isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.Entity)) {
                    const responseDto: IFormLibraryDto = response.data.Entity as IFormLibraryDto;
                    let formLibrary: FormLibraryRow = ConvertFormLibraryDtoToFormLibrary(responseDto);
                    resolve(formLibrary);
                } else {
                    resolve(null);
                }
            });
        } else {
            resolve(null);
        }
    });
};

export const getFormLibraryByEntityName = (entityName?: string) => {
    let relatedFilter: RAFCustomFilter = {};
    relatedFilter.Condition = "and";
    relatedFilter.Rules = [];

    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push(entityName);
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = propertyOf<FormLibraryRow>("Entity");
    relatedFilter.Rules.push(filter1);

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = relatedFilter;
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;
    //listServiceRequest.ViewName = 'all_form_library';
    return new Promise<FormLibraryRow>((resolve) => {
        repositoryActions.postDataAndGetResponse('FormLibrary/List', listServiceRequest, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    let formLibrary: FormLibraryRow[] = response.data.Entities;
                    resolve(formLibrary[0]);
                }
            });
    });
};

export const getFormLibraryByField = (fieldName?: string, fieldValue?: string) => {
    let relatedFilter: RAFCustomFilter = {};
    relatedFilter.Condition = "and";
    relatedFilter.Rules = [];

    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push(fieldValue);
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = fieldName;
    relatedFilter.Rules.push(filter1);

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = relatedFilter;
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;
    //listServiceRequest.ViewName = 'all_form_library';
    return new Promise<FormLibraryRow>((resolve) => {
        repositoryActions.postDataAndGetResponse('FormLibrary/List', listServiceRequest, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    let formLibrary: FormLibraryRow[] = response.data.Entities;
                    resolve(formLibrary[0]);
                }
            });
    });
};
export const getFormLibraryTemplateByField = (fieldName?: string, fieldValue?: string) => {
    let relatedFilter: RAFCustomFilter = {};
    relatedFilter.Condition = "and";
    relatedFilter.Rules = [];

    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push(fieldValue);
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = fieldName;
    relatedFilter.Rules.push(filter1);

    let filter2: RAFCustomFilter = {};
    let filterVal2: string[] = [];
    filterVal2.push(RAFFormType.FormTemplate);
    filter2.Operator = RAFCustomOperator.Equal;
    filter2.Value = filterVal2;
    filter2.Field = "FormType";
    relatedFilter.Rules.push(filter2);

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = relatedFilter;
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;
    //listServiceRequest.ViewName = 'all_form_library';
    return new Promise<FormLibraryRow>((resolve) => {
        repositoryActions.postDataAndGetResponse('FormLibrary/List', listServiceRequest, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    let formLibrary: FormLibraryRow[] = response.data.Entities;
                    resolve(formLibrary[0]);
                }
            });
    });
};

export const getEntities = () => {
    let url = `Entity/List`;
    let moduleListRequest = new ModuleListRequest();
    moduleListRequest.EntityType = RAFEntityType.DataList;

    let sortBy = {
        field: propertyOf<EntityRow>("EntityName"),
        order: "ascending",
    };
    let sortQuery: string[] = [];
    sortQuery.push(
        sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
    );

    moduleListRequest.Sort = sortQuery;

    return new Promise<EntityRow[]>((resolve) => {
        const entitiesStorage = getSessionStorage(RMStorageKey.entities);
        if (isNotNullAndUndefined(entitiesStorage) && !IsNullOrWhiteSpace(entitiesStorage)) {
            const entities: EntityRow[] = JSON.parse(entitiesStorage);
            if (isNotNullAndUndefined(entities)) {
                resolve(entities);
            }
        }
        else {
            return repositoryActions.postDataAndGetResponse(
                url,
                moduleListRequest,
                null,
                ContentType.applicationJson, false
            ).then((response) => {
                const entities: EntityRow[] = response.data.Entities;
                setSessionStorage(RMStorageKey.entities, false, JSON.stringify(entities));
                resolve(entities);
            })
                ;
        }

    });
};

export async function getAllEntities(): Promise<EntityRow[]> {
    const isOnline = await isConnectedToInternet();
    //if (isOnline === true) {
    return getAllEntitiesAPI();
    // }
    // else {
    //     return getAllEntitiesFB();
    // }
}

export const getAllEntitiesAPI = () => {
    //let url = `Entity/ListAll`;
    let url = `Entity/List`;

    const moduleRequest: ModuleListRequest = new ModuleListRequest();

    let sortBy = {
        field: propertyOf<EntityRow>("EntityName"),
        order: "ascending",
    };
    let sortQuery: string[] = [];
    sortQuery.push(
        sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
    );

    moduleRequest.Sort = sortQuery;

    return new Promise<EntityRow[]>((resolve) => {
        const allEntitiesStorage = getSessionStorage(RMStorageKey.all_Entities, true);
        if (isNotNullAndUndefined(allEntitiesStorage) && !IsNullOrWhiteSpace(allEntitiesStorage) && isNotNullAndUndefined(JSON.parse(allEntitiesStorage))) {
            const allEntities: EntityRow[] = JSON.parse(allEntitiesStorage);
            resolve(allEntities);
        }
        else {
            return repositoryActions.postDataAndGetResponse(
                url,
                moduleRequest,
                null,
                ContentType.applicationJson, false
            ).then((response) => {
                if (isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.Entities)) {
                    const allEntitiesList: EntityRow[] = response.data.Entities;
                    if (isNotNullAndUndefined(allEntitiesList) && allEntitiesList.length > 0) {
                        setSessionStorage(RMStorageKey.all_Entities, true, JSON.stringify(allEntitiesList));
                    }
                    resolve(allEntitiesList);
                } else {
                    resolve(null);
                }
            })
                ;
        }

    });
};